// dependencies
import React from 'react';
import { FaFacebook, FaInstagram, FaYelp } from 'react-icons/fa';

const Footer = () => {
    return (
        <div id='footer'>
            <div id='footer-left'>
                <a
                    href='https://www.facebook.com/CasaCarlosRestaurant/'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <FaFacebook />
                </a>
                <a
                    href='https://www.instagram.com/casacarlosmarysville/'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <FaInstagram />
                </a>
                <a
                    href='https://www.yelp.com/biz/casa-carlos-restaurant-and-cantina-marysville-3'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <FaYelp />
                </a>
            </div>
        </div>
    );
};

export default Footer;

// dependencies
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Fade } from 'react-reveal';
import { useNavigate } from 'react-router-dom';
import { Link, Element } from 'react-scroll';
import { HiMiniArrowSmallDown } from 'react-icons/hi2';
// hooks
import useWindowDimensions from '../hooks/useWindowDimensions';
// components
import ImageStack from '../components/ImageStack';
import ImageCarousel from '../components/ImageCarousel';
// assets
import hero from '../assets/images/cantina.jpg';
import featuredImg1 from '../assets/images/soup.jpg';
import featuredImg2 from '../assets/images/salsa.jpg';
import featuredImg3 from '../assets/images/taco-meal.jpg';
import kitchen from '../assets/images/kitchen.jpg';
import blackBG from '../assets/images/black-bg.jpg';
import { ReactComponent as WordLogo } from '../assets/vectors/word-logo.svg';

const onlineOrderingLink = 'https://direct.chownow.com/order/39202/locations/59609';

const HomePage = () => {
    const swiperRef = useRef(null);
    const navigate = useNavigate();
    const { width } = useWindowDimensions();

    const [currentSlide, setCurrentSlide] = useState(0);

    const updateIndex = useCallback(
        () => setCurrentSlide(swiperRef.current.swiper.realIndex),
        []
    );

    useEffect(() => {
        const swiperInstance = swiperRef.current.swiper;

        if (swiperInstance) {
            swiperInstance.on("slideChange", updateIndex);
        }

        return () => {
            if (swiperInstance) {
                swiperInstance.off("slideChange", updateIndex);
            }
        };
    }, [updateIndex]);

    return (
        <div>
            <div id='hero'>
                <Fade big cascade duration={1500}>
                    <div id='hero-text'>
                        <WordLogo id='hero-logo' />
                        <a href={onlineOrderingLink} target='_blank' rel='noopener noreferrer'>
                            <button className='btn'>ORDER NOW</button>
                        </a>
                    </div>
                </Fade>
                <div id='hero-image'>
                    <img src={hero} alt='cantina' />
                </div>
            </div>
            <div id='featured'>
                <Element name='featured'>
                    <div id='featured-img'>
                        <ImageStack imageUrls={[featuredImg1, featuredImg2, featuredImg3]} swiperRef={swiperRef} />
                    </div>
                </Element>
                <div id='featured-text-container'>
                    {currentSlide === 0 ? (
                        <>
                            <Fade bottom cascade duration={500} fraction={.75} spy={currentSlide} appear>
                                <div id='featured-text-header'>
                                    <span>Simplemente</span>
                                    <span>Delicioso</span>
                                </div>
                            </Fade>
                            <Fade
                                big
                                duration={1500}
                                fraction={.75}
                                spy={currentSlide}
                                appear
                            >
                                <div id='featured-text-desc'>
                                    Tender chicken simmered in our homemade broth with black beans and corn. Topped with
                                    tortilla strips, cheddar/jack cheese, cilantro, radish and a hint of lime.
                                </div>
                            </Fade>
                        </>
                    ) : currentSlide === 1 ? (
                        <>
                            <Fade bottom cascade duration={500} fraction={.75} spy={currentSlide}>
                                <div id='featured-text-header'>
                                    <span>Cocina </span>
                                    <span>Con Corazón</span>
                                </div>
                            </Fade>
                            <Fade
                                big
                                duration={1500}
                                fraction={.75}
                                spy={currentSlide}
                                appear
                            >
                                <div id='featured-text-desc'>
                                    A charbroiled Angus steak covered with sautéed peppers, onions, and tomatoes. On the side, our classic rice & refried beans.
                                </div>
                            </Fade>
                        </>
                    ) : (
                        <>
                            <Fade bottom cascade duration={500} fraction={.75} spy={currentSlide}>
                                <div id='featured-text-header'>
                                    <span>Fresco Sabor</span>
                                    <span>Mexicano</span>
                                </div>
                            </Fade>
                            <Fade
                                big
                                duration={1500}
                                fraction={.75}
                                spy={currentSlide}
                                appear
                            >
                                <div id='featured-text-desc'>
                                    A Two Item Combo of a Crispy flour taco made with our homemade tortillas & seasoned ground beef with a saucy, cheesy enchilada, creamy refried beans & fluffy Mexican rice.
                                </div>
                            </Fade>
                        </>
                    )}
                    <div id='featured-bg'>
                        <img src={blackBG} alt='' />
                    </div>
                </div>
            </div>
            <div id='hours'>
                <Fade big cascade duration={1500}>
                    <span id='hours-text'>
                        <span>Open </span><span className='orange-text'>11AM</span><span> to </span><span className='orange-text'>8PM</span><span> Everyday </span>
                    </span>
                </Fade>
                <div>
                    <a href='https://maps.app.goo.gl/si9zUYDpFQjbwv3MA' target="_blank" rel="noopener noreferrer">
                        <button className='btn'>Get Directions</button>
                    </a>
                </div>
                <div id='hours-img'>
                    <img src={kitchen} alt='kitchen' />
                </div>
            </div>
            <div id='more'>
                <ImageCarousel />
                <div id='more-bg'>
                    <img src={blackBG} alt='' />
                </div>
            </div>
            <div id='cta'>
                <button className='btn' onClick={() => navigate('/menu')}>See Our Menus</button>
            </div>
        </div >
    );
};

export default HomePage;

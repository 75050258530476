// dependencies
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import ReactGA from 'react-ga4';
// components
import App from "./App";
// styles
import "./index.scss";

const MEASURMENT_ID = "G-5EKR5G893N";
ReactGA.initialize(MEASURMENT_ID);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Router>
            <App />
        </Router>
    </React.StrictMode>
);

// dependencies
import React from 'react';

const AboutLetter = () => {
    return (
        <div className='about__letter'>
            <p>Welcome valued customer to my family's restaurant. My parents, Eduardo and Carmen Carlos, opened this restaurant in 1964 after my father worked as a foreman for local farmers in Yuba City, CA. During his work as a foreman, my father was responsible for feeding large groups of Mexican workers traveling to California as part of the Bracero Program (1942-1964). My parents, both second generation immigrants from North Central Mexico, used their delicious recipes to bring a taste of home to the field workers at Oji Brothers Farms. My mother often recalled fondly that it was the field workers who encouraged my parents to open their own restaurant. With many sacrifices and entrepreneurial gusto, that is just what they did.</p>
            <p>Since opening over fifty years ago, we have proudly served and employed at least four generations of community members - many who had their first job at Casa Carlos. What started as a small family restaurant has become a local institution, supported by our loyal customers and our outstanding employees- some of whom have worked here for over 20 years!</p>
            <p>My parents' recipes have evolved over the years to match the flavor of our local community. Casa Carlos is proud to offer our own Northern Californian takes on dozens of classic Mexican dishes, resulting in a uniquely delicious menu inspired by the tastes of our beloved patrons.</p>
            <p>I hope you enjoy your time with us and welcome to Casa Carlos - a local tradition.</p>
            <div className='about__letter_signature'>
                <span>Sincerely,</span>
                <span>Ron Carlos</span>
            </div>
        </div>
    );
};

export default AboutLetter;
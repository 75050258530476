// dependencies
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// assets
import { ReactComponent as Logo } from '../assets/vectors/casa-logo.svg';

const onlineOrderingLink = 'https://direct.chownow.com/order/39202/locations/59609';

const MobileNav = ({ view, setView }) => {
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);

    const toggleIsOpen = () => {
        if (isOpen) {
            document.body.classList.remove('no-scroll');
            window.scrollTo(0, 0);
        } else {
            document.body.classList.add('no-scroll');
        }
        setIsOpen((prev) => !prev);
    };

    const changeView = (newView) => {
        toggleIsOpen();
        setView(newView);
    };

    return (
        <div className='mobile-nav-wrap'>
            <div id='mobile-nav'>
                <div id='m-logo-container'>
                    <Logo id='m-logo' onClick={() => navigate('/')} />
                </div>
                <span className='logo-text' onClick={() => navigate('/')}>Casa Carlos</span>
                <div id='mobile-hamburger'>
                    <i className='material-symbols-outlined' onClick={toggleIsOpen}>{isOpen ? 'menu_open' : 'menu'}</i>
                </div>
            </div>
            <div id='mobile-pop-menu' className={!isOpen ? 'closed' : ''}>
                <div className='pop-menu-links'>
                    <span className={view === 'home' ? 'selected' : ''} onClick={() => changeView('home')}>
                        Home
                    </span>
                    <span className={view === 'menu' ? 'selected' : ''} onClick={() => changeView('menu')}>
                        Menu
                    </span>
                    <span className={view === 'about' ? 'selected' : ''} onClick={() => changeView('about')}>
                        About Us
                    </span>
                    <span>
                        <a href={onlineOrderingLink} target='_blank' rel='noopener noreferrer'>
                            Order Online
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
};

const NavBar = ({ view, setView }) => {
    const navigate = useNavigate();
    const [lastScrollTop, setLastScrollTop] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const navbar = document.getElementById('nav');
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            if (scrollTop < 0) {
                return; // Ignore negative scroll (Safari bounce effect)
            }

            if (scrollTop > lastScrollTop) {
                navbar.style.top = '-80px';
            } else {
                navbar.style.top = '0';
            }

            setLastScrollTop(scrollTop);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollTop]);

    return (
        <div id='nav'>
            <div id='nav-left' className='nav-links'>
                <span className={view === 'home' ? 'selected' : ''} onClick={() => setView('home')}>
                    Home
                </span>
                <span className={view === 'about' ? 'selected' : ''} onClick={() => setView('about')}>
                    About Us
                </span>
            </div>
            <div id='logo-container' onClick={() => navigate('/')}>
                <Logo id='logo' />
            </div>
            <div id='nav-right' className='nav-links'>
                <span className={view === 'menu' ? 'selected' : ''} onClick={() => setView('menu')}>
                    Menu
                </span>
                <span>
                    <a href={onlineOrderingLink} target='_blank' rel='noopener noreferrer'>
                        Order Online
                    </a>
                </span>
            </div>
        </div>
    );
};

export { NavBar, MobileNav };

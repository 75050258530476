// dependencies
import React, { useState, useRef } from 'react';
import "react-multi-carousel/lib/styles.css";
// assets
import caro1 from '../assets/images/caro1.jpg';
import caro2 from '../assets/images/caro2.jpg';
import caro3 from '../assets/images/caro3.jpg';
import caro4 from '../assets/images/caro4.jpg';
import caro5 from '../assets/images/caro5.jpg';
import caro6 from '../assets/images/caro6.jpg';
import caro7 from '../assets/images/caro7.jpg';
import caro8 from '../assets/images/salsa2.jpg';
import caro9 from '../assets/images/caro9.jpg';
import caro10 from '../assets/images/caro10.jpg';
import caro11 from '../assets/images/caro11.jpg';

const ImageCarousel = () => {
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startScrollLeft, setStartScrollLeft] = useState(0);
    const ref = useRef(null);

    const handleMouseDown = (e) => {
        const el = ref.current;
        setIsDragging(true);
        setStartX(e.clientX);
        setStartScrollLeft(el.scrollLeft);
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const el = ref.current;
        const x = e.clientX;
        const walk = x - startX;
        el.scrollLeft = startScrollLeft - walk;
    };

    return (
        <div
            id='caro'
            className='caro'
            ref={ref}
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseLeave}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
        >
            <img src={caro4} alt='' className='caro-img' />
            <img src={caro7} alt='' className='caro-img' />
            <img src={caro5} alt='' className='caro-img' />
            <img src={caro10} alt='' className='caro-img' />
            <img src={caro6} alt='' className='caro-img' />
            <img src={caro8} alt='' className='caro-img' />
            <img src={caro1} alt='' className='caro-img' />
            <img src={caro9} alt='' className='caro-img' />
            <img src={caro11} alt='' className='caro-img' />
            <img src={caro2} alt='' className='caro-img' />
            <img src={caro3} alt='' className='caro-img' />
        </div>
    );
};

export default ImageCarousel;
// dependencies
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFlip, Pagination, Navigation } from 'swiper/modules';
// styles
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const ImageStack = ({ imageUrls, swiperRef }) => {
    return (
        <Swiper
            effect={'flip'}
            grabCursor={true}
            pagination={true}
            navigation={true}
            modules={[EffectFlip, Pagination, Navigation]}
            ref={swiperRef}
        >
            {imageUrls.map((image, idx) => (
                <SwiperSlide key={idx}>
                    <img src={image} alt='' className='swiper-img' />
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default ImageStack;

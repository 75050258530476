// dependencies
import React from 'react';
import { Fade } from 'react-reveal';
// components
import AboutLetter from '../components/AboutLetter';
// assets
import aboutBanner from '../assets/images/about1.jpg';
import aboutBanner2 from '../assets/images/about5.jpg';
import footerImg1 from '../assets/images/about2.jpg';
import footerImg2 from '../assets/images/about3.jpg';
import footerImg3 from '../assets/images/about4.jpg';

const AboutUs = () => {
    return (
        <div className='about'>
            <div className='about__banner'>
                <Fade big cascade duration={1500}>
                    <span className='about__banner_text'>Since 1964</span>
                </Fade>
                <div className='about__banner_img'>
                    <img src={aboutBanner} alt='' />
                </div>
            </div>
            <AboutLetter />
            <div className='about__footer'>
                <div className='about__footer_row'>
                    <div className='about__footer_img about-1'>
                        <img src={footerImg3} alt='' />
                    </div>
                    <div className='about__footer_img about-2'>
                        <img src={footerImg2} alt='' />
                    </div>
                    <div className='about__footer_img about-3'>
                        <img src={footerImg1} alt='' />
                    </div>
                </div>
                <div className='about__banner'>
                    <div className='about__banner_img-color'>
                        <img src={aboutBanner2} alt='' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;

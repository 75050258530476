// dependencies
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
// components
import { NavBar, MobileNav } from './components/NavBar';
import Footer from './components/Footer';
// views
import HomePage from './views/HomePage';
import AboutUs from './views/AboutUs';
import Menu from './views/Menu';
import { useEffect } from 'react';

function App() {
    const location = useLocation();
    const navigate = useNavigate();
    const view = location.pathname.split('/').pop();

    const changeView = (newView) => {
        navigate(`/${newView}`);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [view]);

    return (
        <>
            <MobileNav view={view} setView={changeView} />
            <NavBar view={view} setView={changeView} />
            <Routes>
                <Route exact path='/home' element={<HomePage />} />
                <Route exact path='/about' element={<AboutUs />} />
                <Route exact path='/menu' element={<Menu />} />
                <Route exact path='*' element={<Navigate to='/home' replace />} />
            </Routes>
            <Footer />
        </>
    );
}

export default App;

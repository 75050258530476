// dependencies
import React from 'react';

const PriceTag = ({ price }) => {
    const priceSplit = price.split('.');
    const dollars = priceSplit[0];
    const cents = priceSplit[1];

    return (
        <div className='price-tag'>
            <span className='price-tag__dollars'>${dollars || '0'}</span>
            <span className='price-tag__cents'>.{cents || '00'}</span>
        </div>
    );
};

export default PriceTag;